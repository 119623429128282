import { RouteConfig } from 'vue-router';
const BoardFolder = () => import('./views/board-folder.vue');
const Daping = () => import('./views/daping.vue');
const routers: RouteConfig[] = [
    {
        path: 'board-folder',
        component: BoardFolder,
    },
    {
        path: 'daping',
        component: Daping,
    },
    {
        path: '*',
        redirect: 'board-folder'
    },
];
export default routers;
