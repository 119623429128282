import { RouteConfig } from 'vue-router';
const ExportResult = () => import('./views/export-result.vue');
const routers: RouteConfig[] = [
    {
        path: 'export-result',
        component: ExportResult,
    },
    {
        path: '*',
        redirect: 'export-result'
    },
];
export default routers;
