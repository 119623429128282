import { RouteConfig } from 'vue-router';
const HelpFolder = () => import('./views/help-folder.vue');
const routers: RouteConfig[] = [
    {
        path: 'help-folder',
        component: HelpFolder,
    },
    {
        path: '*',
        redirect: 'help-folder'
    },
];
export default routers;
