import { RouteConfig } from 'vue-router';
const WajueFolder = () => import('./views/wajue-folder.vue');
const WajueResult = () => import('./views/wajue-result.vue');
const routers: RouteConfig[] = [
    {
        path: 'wajue-folder',
        component: WajueFolder,
    },
    {
        path: 'wajue-result',
        component: WajueResult,
    },
    {
        path: '*',
        redirect: 'wajue-folder'
    },
];
export default routers;
