import { RouteConfig } from 'vue-router';
const SafeFolder = () => import('./views/safe-folder.vue');
const routers: RouteConfig[] = [
    {
        path: 'safe-folder',
        component: SafeFolder,
    },
    {
        path: '*',
        redirect: 'safe-folder'
    },
];
export default routers;
