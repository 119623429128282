import { RouteConfig } from 'vue-router';
const FilterList = () => import('./views/filter-list.vue');
const routers: RouteConfig[] = [
    {
        path: 'filter-list',
        component: FilterList,
    },
    {
        path: '*',
        redirect: 'filter-list'
    },
];
export default routers;
