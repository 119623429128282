import { RouteConfig } from 'vue-router';
const Login = () => import('./views/login/login.vue');
const routers: RouteConfig[] = [
    {
        path: 'login',
        name: 'login',
        component: Login
    },
    {
        path: '',
        redirect: 'login'
    },
];
export default routers;

