import { RouteConfig } from 'vue-router';
const Account = () => import('../account/account.vue');
const Home = () => import('../home/home.vue');
const Datacenter = () => import('../datacenter/datacenter.vue');
const Dataintegration = () => import('../dataintegration/dataintegration.vue');
const Storehouse = () => import('../storehouse/storehouse.vue');
const Research = () => import('../research/research.vue');
const Analysis = () => import('../analysis/analysis.vue');
const Manage = () => import('../manage/manage.vue');
const Export = () => import('../export/export.vue');
const Topic = () => import('../topic/topic.vue');
import AccountRouterChildren from '../account/account.router';
import HomeRouterChildren from '../home/home.router';
import DataintegrationRouterChildren from '../dataintegration/dataintegration.router';
import DatacenterRouterChildren from '../datacenter/datacenter.router';
import StorehouseRouterChildren from '../storehouse/storehouse.router';
import ResearchRouterChildren from '../research/research.router';
import AnalysisRouterChildren from '../analysis/analysis.router';
import ManageRouterChildren from '../manage/manage.router';
import ExportRouterChildren from '../export/export.router';
import TopicRouterChildren from '../topic/topic.router';
const routers: RouteConfig[] = [
    {
        path: 'account',
        component: Account,
        children: AccountRouterChildren
    },
    {
        path: 'home',
        component: Home,
        children: HomeRouterChildren
    },
    {
        path: 'datacenter',
        component: Datacenter,
        children: DatacenterRouterChildren
    },
    {
        path: 'dataintegration',
        component: Dataintegration,
        children: DataintegrationRouterChildren
    },
    {
        path: 'storehouse',
        component: Storehouse,
        children: StorehouseRouterChildren
    },
    {
        path: 'research',
        component: Research,
        children: ResearchRouterChildren
    },
    {
        path: 'analysis',
        component: Analysis,
        children: AnalysisRouterChildren
    },
    {
        path: 'manage',
        component: Manage,
        children: ManageRouterChildren
    },
    {
        path: 'export',
        component: Export,
        children: ExportRouterChildren
    },
    {
        path: 'topic',
        component: Topic,
        children: TopicRouterChildren
    },
];
export default routers;

