import { RouteConfig } from 'vue-router';
const TongjiFolder = () => import('./views/tongji-folder.vue');
const TongjiResult = () => import('./views/tongji-result.vue');
const routers: RouteConfig[] = [
    {
        path: 'tongji-folder',
        component: TongjiFolder,
    },
    {
        path: 'tongji-result',
        component: TongjiResult,
    },
    {
        path: '*',
        redirect: 'tongji-folder'
    },
];
export default routers;
