import { RouteConfig } from 'vue-router';
const Board = () => import('./board/board.vue');
const Pool = () => import('./pool/pool.vue');
const Filter = () => import('./filter/filter.vue');
const His = () => import('./his//his.vue');
import BoardRouterChildren from './board/board.router';
import PoolRouterChildren from './pool/pool.router';
import FilterRouterChildren from './filter/filter.router';
import HisRouterChildren from './his/his.router';
const routers: RouteConfig[] = [
    {
        path: 'board',
        component: Board,
        children: BoardRouterChildren
    },
    {
        path: 'pool',
        component: Pool,
        children: PoolRouterChildren
    },
    {
        path: 'filter',
        component: Filter,
        children: FilterRouterChildren
    },
    {
        path: 'his',
        component: His,
        children: HisRouterChildren
    },
    {
        path: '*',
        redirect: 'pool'
    },
];
export default routers;

