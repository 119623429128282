import { RouteConfig } from 'vue-router';
const HomeShow = () => import('../home/views/home-show.vue');
const routers: RouteConfig[] = [
    {
        path: 'home-show',
        component: HomeShow,
    },
    {
        path: '*',
        redirect: 'home-show'
    },
];
export default routers;