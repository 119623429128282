import Vue from 'vue'
import Component from 'vue-class-component';
import App from './App.vue'
import router from './router';
import store from './store';
import Router from 'vue-router'
import { getOption } from '@/setting';

import html2canvas from "html2canvas"
import JSPDF from "jspdf"

import Print from 'vue-print-nb'
Vue.use(Print)
Vue.prototype.ExportSavePdf = (htmlTitle: any, currentTime: any) => {
  var element = document.getElementById("pdfCentent")
  html2canvas(element as any, {
    logging: false,
    // dpi: window.devicePixelRatio * 4,
    scale: 4,
    useCORS: true
  }).then((canvas: any) => {
    var pdf = new JSPDF("p", "mm", "a4") // A4纸，纵向
    var ctx = canvas.getContext("2d")
    var a4w = 200; var a4h = 287 // A4大小，210mm x 297mm，四边各保留20mm的边距，显示区域170x257
    var imgHeight = Math.floor(a4h * canvas.width / a4w) // 按A4显示比例换算一页图像的像素高度
    // var imgHeight = Math.floor(a4h * canvas.width / a4w) // 按A4显示比例换算一页图像的像素高度
    var renderedHeight = 0
    while (renderedHeight < canvas.height) {
      var page: any = document.createElement("canvas")
      page.width = canvas.width
      page.height = Math.min(imgHeight, canvas.height - renderedHeight)// 可能内容不足一页

      // 用getImageData剪裁指定区域，并画到前面创建的canvas对象中
      page.getContext("2d").putImageData(ctx.getImageData(0, renderedHeight, canvas.width, Math.min(imgHeight, canvas.height - renderedHeight)), 0, 0)

      pdf.addImage(page.toDataURL("image/jpeg", 1.0), "JPEG", 10, 10, a4w, Math.min(a4h, a4w * page.height / page.width)) // 添加图像到页面，保留10mm边距

      renderedHeight += imgHeight
      if (renderedHeight < canvas.height) { pdf.addPage() }// 如果后面还有内容，添加一个空页
      // delete page;
    }
    pdf.save(htmlTitle + currentTime)
  })
}

// echarts
import 'echarts-gl';
import VueEcharts from "vue-echarts-ts";
Vue.use(VueEcharts);

import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import "../src/theme/theme.scss";
Vue.use(ElementUI);

// global_css
import './style.scss';
import './assets/style/global.scss'; // 引入全局样式  

Vue.filter('dataFormat', (input: string, pattern = '') => {
  const dt = new Date(input);
  // 获取年月日
  const y = dt.getFullYear();
  const M = (dt.getMonth() + 1).toString().padStart(2, '0');
  const d = dt.getDate().toString().padStart(2, '0');
  const hh = dt.getHours().toString().padStart(2, '0');
  const mm = dt.getMinutes().toString().padStart(2, '0');
  const ss = dt.getSeconds().toString().padStart(2, '0');
  // 如果 传递进来的字符串类型，转为小写之后，等于 yyyy-mm-dd，那么就返回 年-月-日
  // 否则，就返回  年-月-日 时：分：秒
  if (pattern === 'yyyy/MM/dd') {
    return `${y}/${M}/${d}`;
  } else if (pattern === 'HH:mm:ss') {
    return `${hh}:${mm}:${ss}`;
  } else {
    return `${y}-${M}-${d} ${hh}:${mm}:${ss}`;
  }
});


Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate'
]);

Vue.config.productionTip = false

const originalPush: any = Router.prototype.push
Router.prototype.push = function push(location: any) {
  return originalPush.call(this, location).catch((err: any) => err)
}

new Vue({
  router,
  store,
  render: (h) => {
    getOption().then((data: any) => {
      window.OPTION = data;
    });
    return h(App);
  },
}).$mount('#app')
