import { RouteConfig } from 'vue-router';
const Zhanghu = () => import('./zhanghu/zhanghu.vue');
const Juese = () => import('./juese/juese.vue');
const Zuzhi = () => import('./zuzhi/zuzhi.vue');
const Quanxian = () => import('./quanxian/quanxian.vue');
const Yaoqing = () => import('./yaoqing/yaoqing.vue');
const Ziliao = () => import('./ziliao/ziliao.vue');
import ZhanghuRouterChildren from './zhanghu/zhanghu.router';
import JueseRouterChildren from './juese/juese.router';
import ZuzhiRouterChildren from './zuzhi/zuzhi.router';
import QuanxianRouterChildren from './quanxian/quanxian.router';
import YaoqingRouterChildren from './yaoqing/yaoqing.router';
import ZiliaoRouterChildren from './ziliao/ziliao.router';
const routers: RouteConfig[] = [
    {
        path: 'zhanghu',
        component: Zhanghu,
        children: ZhanghuRouterChildren
    },
    {
        path: 'juese',
        component: Juese,
        children: JueseRouterChildren
    },
    {
        path: 'zuzhi',
        component: Zuzhi,
        children: ZuzhiRouterChildren
    },
    {
        path: 'quanxian',
        component: Quanxian,
        children: QuanxianRouterChildren
    },
    {
        path: 'yaoqing',
        component: Yaoqing,
        children: YaoqingRouterChildren
    },
    {
        path: 'ziliao',
        component: Ziliao,
        children: ZiliaoRouterChildren
    },
    {
        path: '*',
        redirect: 'zhanghu'
    },
];
export default routers;

