import { RouteConfig } from 'vue-router';
const Eye = () => import('./eye/eye.vue');
const Hand = () => import('./hand/hand.vue');
const Tongue = () => import('./tongue/tongue.vue');
const Pulse = () => import('./pulse/pulse.vue');
import TongueRouterChildren from './tongue/tongue.router';
import EyeRouterChildren from './eye/eye.router';
import HandRouterChildren from './hand/hand.router';
import PulseRouterChildren from './pulse/pulse.router';
const routers: RouteConfig[] = [
    {
        path: 'tongue',
        component: Tongue,
        children: TongueRouterChildren
    },
    {
        path: 'eye',
        component: Eye,
        children: EyeRouterChildren
    },
    {
        path: 'hand',
        component: Hand,
        children: HandRouterChildren
    },
    {
        path: 'pulse',
        component: Pulse,
        children: PulseRouterChildren
    },
    {
        path: '*',
        redirect: 'tongue'
    },
];
export default routers;

