import Vue from 'vue';
import Vuex from 'vuex';
import axios from '@/plugins/axios';
Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    configuration: {},
    user: {},
    token: '',
    patient: "", // 当前患者
    centers: "",// 当前用户专病列表
    center: "",// 当前选中的专病
    cZhenci: {}, // 当前诊次数据
    cContent: {},// 当前量表信息
    cTemplate: {},// 当前模板信息
    zhanghu: {},// 添加账户详情获取不到角色，从列表页携带不友好，采取store存储方式
    isShowZhuanbingku: true,// 是否显示专病库,后端获取的，跟用户权限里面的控制按钮相关
    ifCompose: false,// 当前编辑状态
    ifZhiyi: false,// 当前质疑状态
    roleControl: {
      '监查痕迹': ['项目负责人', '监查员', '专病负责人', '分中心负责人', '质量审核员', '主要研究者', '研究者'],
      '批量导出': ['项目负责人', '专病负责人', '主要研究者', '分中心负责人'],
      '数据录入': ['主要研究者', '研究者'],
      '质疑': ['监查员', '质量审核员'],
      '提交': ['主要研究者', '研究者', '监查员', '质量审核员'],
      '审核通过': ['监查员', '专病负责人', '分中心负责人', '质量审核员'],
      '确认无效': ['主要研究者', '研究者'],
      '复活': ['专病负责人'],
      '打回草稿': ['质量审核员']
    }

  },
  mutations: {
    updateToken(state, token) {
      state.token = token;
      localStorage.setItem('token', token);
    },
    updateUser(state, data) {
      state.user = data;
    },
    updateConfiguration(state, data) {
      state.configuration = data;
    },
    updateCenter(state, data) {
      state.center = data;
      localStorage.setItem('center', JSON.stringify(data));
    },
    updateCenters(state, data) {
      state.centers = data;
    },
    updateCZhenci(state, data) {
      state.cZhenci = data;
    },
    updateCContent(state, data) {
      state.cContent = data;
    },
    updateCTemplate(state, data) {
      state.cTemplate = data;
    },
    updateZhanghu(state, data) {
      state.zhanghu = data;
    },
    updateIsShowZhuanbingku(state, data) {
      state.isShowZhuanbingku = data;
    },
    updatePatient(state, data) {
      state.patient = data;
      localStorage.setItem('patient', JSON.stringify(data));
    },
    updateIfCompose(state, ifCompose) {
      state.ifCompose = ifCompose;
    },
    updateIfZhiyi(state, ifZhiyi) {
      state.ifZhiyi = ifZhiyi;
    },
  },
  actions: {
    async getUser({ commit }) {
      const { data } = await axios.get('api/user');
      commit('updateUser', data.data);
    },
  },
});

export default store;
