import { RouteConfig } from 'vue-router';
const Safe = () => import('./safe/safe.vue');
const Personal = () => import('./personal/personal.vue');
const Help = () => import('./help/help.vue');
import SafeRouterChildren from './safe/safe.router';
import PersonalRouterChildren from './personal/personal.router';
import HelpRouterChildren from './help/help.router';
const routers: RouteConfig[] = [
    {
        path: 'safe',
        component: Safe,
        children: SafeRouterChildren
    },
    {
        path: 'personal',
        component: Personal,
        children: PersonalRouterChildren
    },
    {
        path: 'help',
        component: Help,
        children: HelpRouterChildren
    },
    {
        path: '*',
        redirect: 'safe'
    },
];
export default routers;

