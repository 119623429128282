import { RouteConfig } from 'vue-router';
const ShetuFolder = () => import('./views/shetu-folder.vue');
const ShetuResult = () => import('./views/shetu-result.vue');
const routers: RouteConfig[] = [
    {
        path: 'shetu-folder',
        component: ShetuFolder,
    },
    {
        path: 'shetu-result',
        component: ShetuResult,
    },
    {
        path: '*',
        redirect: 'shetu-folder'
    },
];
export default routers;
