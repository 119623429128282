import { RouteConfig } from 'vue-router';
const List = () => import('./views/list.vue');
const BiduiList = () => import('./views/bidui-list.vue');
const BiduiPreview = () => import('./views/bidui-preview.vue');
const ReportPreview = () => import('./views/report-preview.vue');
const Biaozhu = () => import('./views/biaozhu/biaozhu.vue');
const Dictionary = () => import('./views/dictionary.vue');
const routers: RouteConfig[] = [
    {
        path: 'list',
        component: List,
    },
    {
        path: 'bidui-list',
        component: BiduiList,
    },
    {
        path: 'bidui-preview',
        component: BiduiPreview,
    },
    {
        path: 'report-preview',
        component: ReportPreview,
    },
    {
        path: 'biaozhu',
        component: Biaozhu,
    },
    {
        path: 'dictionary',
        component: Dictionary,
    },
    {
        path: '*',
        redirect: 'list'
    },
];
export default routers;
