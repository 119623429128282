import { RouteConfig } from 'vue-router';
const Shetu = () => import('./shetu/shetu.vue');
const Wajue = () => import('./wajue/wajue.vue');
const Tongji = () => import('./tongji/tongji.vue');
import ShetuRouterChildren from './shetu/shetu.router';
import WajueRouterChildren from './wajue/wajue.router';
import TongjiRouterChildren from './tongji/tongji.router';
const routers: RouteConfig[] = [
    {
        path: 'shetu',
        component: Shetu,
        children: ShetuRouterChildren
    },
    {
        path: 'wajue',
        component: Wajue,
        children: WajueRouterChildren
    },
    {
        path: 'tongji',
        component: Tongji,
        children: TongjiRouterChildren
    },
    {
        path: '*',
        redirect: 'tongue'
    },
];
export default routers;

