import { RouteConfig } from 'vue-router';
const StorehouseSelect = () => import('../storehouse/views/storehouse-select.vue');
const Collect = () => import('./collect/collect.vue');
const Case = () => import('./case/case.vue');
const Control = () => import('./control/control.vue');
const Mark = () => import('./mark/mark.vue');
const Report = () => import('./report/report.vue');
import CollectRouterChildren from './collect/collect.router';
import CaseRouterChildren from './case/case.router';
import ControlRouterChildren from './control/control.router';
import MarkRouterChildren from './mark/mark.router';
import ReportRouterChildren from './report/report.router';
const routers: RouteConfig[] = [
    {
        path: 'storehouse-select',
        component: StorehouseSelect,
    },
    {
        path: 'collect',
        component: Collect,
        children: CollectRouterChildren
    },
    {
        path: 'case',
        component: Case,
        children: CaseRouterChildren
    },
    {
        path: 'control',
        component: Control,
        children: ControlRouterChildren
    },
    {
        path: 'mark',
        component: Mark,
        children: MarkRouterChildren
    },
    {
        path: 'report',
        component: Report,
        children: ReportRouterChildren
    },
    {
        path: '*',
        redirect: 'storehouse-select'
    },
];
export default routers;

