import { RouteConfig } from 'vue-router';
const List = () => import('./views/list.vue');
const Compose = () => import('./views/compose.vue');
const Forbid = () => import('./views/forbid.vue');
const routers: RouteConfig[] = [
    {
        path: 'list',
        component: List,
    },
    {
        path: 'compose',
        component: Compose,
    },
    {
        path: 'forbid',
        component: Forbid,
    },
    {
        path: '*',
        redirect: 'list'
    },
];
export default routers;

