import { RouteConfig } from 'vue-router';
const Caijishuoming = () => import('./caijishuoming/caijishuoming.vue');
import CaijishuomingRouterChildren from './caijishuoming/caijishuoming.router';
const routers: RouteConfig[] = [
    {
        path: 'caijishuoming',
        component: Caijishuoming,
        children: CaijishuomingRouterChildren
    },
    {
        path: '*',
        redirect: 'caijishuoming'
    },
];
export default routers;