import { RouteConfig } from 'vue-router';
const List = () => import('./views/list.vue');
const Detail = () => import('./views/detail.vue');
const DataExport = () => import('./views/data-export.vue');
const Recycle = () => import('./views/recycle.vue');
const routers: RouteConfig[] = [
    {
        path: 'list',
        component: List,
    },
    {
        path: 'detail',
        component: Detail,
    },
    {
        path: 'data-export',
        component: DataExport,
    },
    {
        path: 'recycle',
        component: Recycle,
    },
    {
        path: '*',
        redirect: 'list'
    },
];
export default routers;

