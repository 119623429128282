import { RouteConfig } from 'vue-router';
const PersonalFolder = () => import('./views/personal-folder.vue');
const routers: RouteConfig[] = [
    {
        path: 'personal-folder',
        component: PersonalFolder,
    },
    {
        path: '*',
        redirect: 'personal-folder'
    },
];
export default routers;
