import { RouteConfig } from 'vue-router';
const List = () => import('./views/list.vue');
const Biaozhu = () => import('./views/bioazhu/biaozhu.vue');
const Preview = () => import('./views/preview.vue');
const Export = () => import('./views/export.vue');
const Dictionary = () => import('./views/dictionary.vue');
const routers: RouteConfig[] = [
    {
        path: 'list',
        component: List,
    },
    {
        path: 'biaozhu',
        component: Biaozhu,
    },
    {
        path: 'preview',
        component: Preview,
    },
    {
        path: 'biaozhu',
        component: Biaozhu,
    },
    {
        path: 'export',
        component: Export,
    },
    {
        path: 'dictionary',
        component: Dictionary,
    },
    {
        path: '*',
        redirect: 'list'
    },
];
export default routers;
