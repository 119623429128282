import { RouteConfig } from 'vue-router';
const PoolList = () => import('./views/pool-list.vue');
const PoolBoard = () => import('../board/views/board-folder.vue');
const PoolExport = () => import('../filter/views/filter-list.vue');
const Duijie = () => import('./views/duijie.vue');
const routers: RouteConfig[] = [
    {
        path: 'pool-list',
        component: PoolList,
    },
    {
        path: 'pool-board',
        component: PoolBoard,
    },
    {
        path: 'pool-export',
        component: PoolExport,
    },
    {
        path: 'duijie',
        component: Duijie,
    },
    {
        path: '*',
        redirect: 'pool-list'
    },
];
export default routers;
