






import { Component, Vue } from "vue-property-decorator";
import store from "./store";
import { configuration } from "@/setting";

@Component({})
export default class App extends Vue {
  private created() {
    let state = sessionStorage.getItem("state");
    if (state) {
      this.$store.replaceState(
        Object.assign(this.$store.state, JSON.parse(state))
      );
      sessionStorage.removeItem("state");
    }
  }

  private mounted() {
    // 保证页面刷新时store的值不丢失
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("state", JSON.stringify(this.$store.state));
    });
    // 保证点击页面的后退和前进按钮时模块选中效果同时变化
    // window.addEventListener("popstate", e => {
    //   this.$store.commit("setHistory", e.target.location.href);
    // });
    store.commit("updateConfiguration", configuration);
    document.documentElement.style.setProperty(
      "--primaryColor",
      configuration.theme && configuration.theme.primaryColor
        ? configuration.theme.primaryColor
        : "#0A97A2"
    );
    document.documentElement.style.setProperty(
      "--subPrimaryColor",
      configuration.theme && configuration.theme.subPrimaryColor
        ? configuration.theme.subPrimaryColor
        : "#84CBD0"
    );
    document.documentElement.style.setProperty(
      "--miniPrimaryColor",
      configuration.theme && configuration.theme.miniPrimaryColor
        ? configuration.theme.miniPrimaryColor
        : "#E1F2F4"
    );
    document.documentElement.style.setProperty(
      "--headerBg",
      configuration.theme && configuration.theme.headerBg
        ? configuration.theme.headerBg
        : "linear-gradient(#ffffff, #d8ecf0)"
    );
    document.documentElement.style.setProperty(
      "--NavLeftBor",
      configuration.theme && configuration.theme.NavLeftBor
        ? configuration.theme.NavLeftBor
        : "#4dd6e1"
    );
    document.documentElement.style.setProperty(
      "--NavLeftAct",
      configuration.theme && configuration.theme.NavLeftAct
        ? configuration.theme.NavLeftAct
        : "#23aeb8"
    );
    document.documentElement.style.setProperty(
      "--TableHeader",
      configuration.theme && configuration.theme.TableHeader
        ? configuration.theme.TableHeader
        : "#E1F2F4"
    );
    document.documentElement.style.setProperty(
      "--scrollbarColor",
      configuration.theme && configuration.theme.scrollbarColor
        ? configuration.theme.scrollbarColor
        : "rgba(20, 168, 156, 0.4)"
    );
  }
  beforeDestroy() {
    window.removeEventListener("beforeunload", () => {
      sessionStorage.setItem("state", JSON.stringify(this.$store.state));
    });
    // window.removeEventListener("popstate");
  }
}
